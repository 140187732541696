console.group("inicializando o pack js das gems do site")
console.group("inicializando o jquery-mobile")
import './jquery.mobile.js';

console.groupEnd()

console.group("inicializando componentes da uxkit")


import InputChangeObserver from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/components/change_observer.js";
import AgentHelper from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/helpers/agent.js";
import Util from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/helpers/generic.js";
import SizingHelper from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/helpers/sizing.js";
import StringHelper from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/extensions/string.js";
import ArrayHelper from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/extensions/array.js";
import CookieHelper from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/helpers/cookie.js";

StringHelper.installMoneyParser();
StringHelper.installStringFormat();
ArrayHelper.installRemoveItem();

global.checkIfEmpty = Util.isEmpty;
global.resizeFullElements = SizingHelper.resizeFullElements;
global.goBackSafely = Util.goBackSafely;
global.goForwardSafely = Util.goForwardSafely;
global.InputChangeObserver = InputChangeObserver;
global.isMobile = AgentHelper;
global.createCookie = CookieHelper.create;
global.readCookie = CookieHelper.read;
global.eraseCookie = CookieHelper.remove;

import HolderComponent from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/components/holder.js";
import ContainerComponent from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/components/container.js";
import EmptyBuilder from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/components/builders/empty.js";
import SectionHelperContainerBuilder from "./helper_builder.js";
import HolderBuilder from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/components/builders/empty.js";
import AsyncViewProxy from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/components/proxies/base.js";
import CancellableAsyncViewProxy from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/components/proxies/cancellable.js";
import LoadingWidget from "/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/js/components/loading.js";

global.LoadingWidget = LoadingWidget;
global.HolderComponent = HolderComponent;
global.ContainerComponent = ContainerComponent;
global.AsyncViewProxy = AsyncViewProxy;
global.CancellableAsyncViewProxy = CancellableAsyncViewProxy
global.EmptyContainerBuilder = EmptyBuilder;
global.SectionHelperContainerBuilder = SectionHelperContainerBuilder;
global.HolderBuilder = HolderBuilder;
console.groupEnd()

console.group("inicializando componentes da navkit")


import MatrixInnerHolderComponent from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/holder.js";
import EmptyMatrixInnerHolderBuilder from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/builders/empty_matrix.js";
import MatrixInnerHolderBuilder from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/builders/inner_matrix.js";
import SimpleContainerBuilder from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/builders/simple.js";

global.MatrixInnerHolderComponent = MatrixInnerHolderComponent;
global.EmptyMatrixInnerHolderBuilder = EmptyMatrixInnerHolderBuilder;
global.MatrixInnerHolderBuilder = MatrixInnerHolderBuilder;
global.SimpleContainerBuilder = SimpleContainerBuilder;

import PlusNavigationMatrixMechanic from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/mech.js";
import ChildNavigationControls from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/controls/child.js";
import ChildNavigationStrategy from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/strategies/child.js";
import DefaultPlusNavigationStrategy from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/strategies/plus.js";
import DefaultPlusNavigationControls from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/controls/plus.js";

global.PlusNavigationMatrixMechanic = PlusNavigationMatrixMechanic;
global.ChildNavigationControls = ChildNavigationControls;
global.ChildNavigationStrategy = ChildNavigationStrategy;
global.DefaultPlusNavigationStrategy = DefaultPlusNavigationStrategy;
global.DefaultPlusNavigationControls = DefaultPlusNavigationControls;

import SwipeFix from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/libs/jquery_mob_swipe_fix.js";
import MatrixBodyFacade from "/var/www/apps/obj/ruby/engines/navkit/app/frontend/js/components/facade.js";

new SwipeFix().fix();

global.MatrixBodyFacade = MatrixBodyFacade;
console.groupEnd()
console.groupEnd()

