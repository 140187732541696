console.group("inicializando o pack css das gems do site")

  console.log("inicializando componentes da uxkit")
  
  import '/var/www/apps/obj/ruby/engines/ux_kit19/app/frontend/util/css/components/user.scss';

  console.log("inicializando componentes da navkit")
  
  import '/var/www/apps/obj/ruby/engines/navkit/app/frontend/css/init.js';

console.groupEnd();
