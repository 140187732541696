export default function SimpleContainerBuilder(w) {
    var self = null;
    var wrapped = null;

    this.getParams = function() {
        return wrapped.getParams();
    }

    this.build = function(container, ele) {
        var containerSelector = container.getContainerSelector();
        var contentSelector = container.getContentSelector();
        var content = $("<div></div>");

        if (ele == null || typeof(ele) == 'undefined')
            ele = $("<div></div>");

        if (containerSelector.search("#") >= 0) {
            ele.attr("id", containerSelector.replace(/#/g, ""));
        } else if (containerSelector.search(".") >= 0) {
            ele.addClass(containerSelector.replace(/\./g, " "));
        }

        if (contentSelector.search("#") >= 0) {
            content.attr("id", contentSelector.replace(/#/g, ""));
        } else if (contentSelector.search(".") >= 0) {
            content.addClass(contentSelector.replace(/\./g, " "));
        }

        ele.append(createPendingHolder(container));

        ele.append(content);

        if (wrapped != null) {
            return wrapped.build(container, ele);
        }

        return ele;
    }

    var createPendingHolder = function(container) {
        var ph = $("<div></div>");

        ph.addClass("pending");
        ph.append("<div class='loader'><div class='inner'><div class='left'></div><div class='middle'></div><div class='right'></div></div><ul><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li><li></li></ul></div>");

        return ph;
    };


    this.configure = function(container, ele) {
        container.setLoading(false);
        container.getHolder().getFacade().setLoading(false);
        container.setAlreadyLoaded(true);
        container.didLoad();

        /* fix iOS fixed bottombar */
        //if (isMobile.iOS()) {
        //    c.getHolder().getElement().attr("style", "padding-bottom: 68px !important");
        //}

        if (wrapped != null)
            return wrapped.configure(container, ele);

        return ele;
    }

    var __construct = function (that) {
        self = that;
        wrapped = w;
    }(this)
}